

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";

import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Polynucleotidesfaq() {
    return (
        <Section py={4} id="faq" bg="#ffffff" className="pb-md-5 mb pt-5 mt-2 pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>Polynucleotides</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="pt-5">
       
       


        <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >


<Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What areas can be treated with polynucleotides?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Polynucleotide treatments can be applied to various areas, including the face, neck, décolletage, around the eyes, and hands.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How many sessions will I need?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The number of sessions required varies based on individual needs and desired results. Most patients benefit from a series of 3-4 treatments spaced 3-4 weeks apart.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Is the treatment painful?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The treatment involves minimal discomfort. A topical anesthetic can be applied to the area to ensure a comfortable experience.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    When will I see results?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Initial results can be seen within a few weeks, with continued improvement over several months after repeated sessions as collagen production increases.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion> <br />   
    
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What is the downtime after a facial rejuvenation treatment with polynucleotides?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    There is typically minimal downtime after a polynucleotide facial treatment. Patients may experience mild redness or swelling, which subsides within a day or two.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Can polynucleotides be combined with other cosmetic treatments?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Yes, polynucleotide treatments can be effectively combined with other treatments such as microneedling to enhance results.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Are there any side effects associated with polynucleotide treatments?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Side effects are minimal and may include temporary redness, swelling, or bruising at the injection site. These usually resolve quickly.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How long do the effects of polynucleotide treatments last?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Results vary among individuals but typically last around 6-12 months. Maintenance treatments can prolong the effects.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What skin concerns can be addressed with polynucleotides?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Polynucleotide treatments are effective for improving skin texture, reducing fine lines and wrinkles, and enhancing overall skin quality and radiance.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Can polynucleotide treatments help with acne scars?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Yes, polynucleotide injections can stimulate collagen production and improve the appearance of acne scars over time.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Is there an age limit for polynucleotide treatments?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Polynucleotide treatments are suitable for adults of all ages who are looking to improve the health and appearance of their skin.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How soon can I wear makeup after a polynucleotide treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    It is generally recommended to wait at least 24 hours before applying makeup to allow the skin to fully heal.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Can polynucleotide treatments be used for body contouring?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Yes, polynucleotide injections can be used to tighten and firm loose skin on various body areas, such as the abdomen, thighs, and arms.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How should I prepare for a polynucleotide treatment session?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Prior to your appointment, avoid using harsh skincare products and notify your provider of any allergies or medical conditions. It's also recommended to stay hydrated and refrain from smoking.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>

<br /> 
          </Col>



        </Row>




              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
               
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}







